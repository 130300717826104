<template>
    <span id="account-info">
        <button class="account-button" :class="{ active: account_dropdown_active }" @click="accountDropdownToggle">
            {{ username }}
            <img alt="Account Picture" src="../../../assets/account-image-white.png">
            <i v-if="account_dropdown_active" class="pi pi-chevron-up"></i>
            <i v-else class="pi pi-chevron-down"></i>
        </button>
        <div class="dropdown-content" v-if="account_dropdown_active">
            <a href="mailto:sprinthelp@amey.co.uk" target="_blank">
                <button>
                    Contact us <i class="pi pi-envelope"></i>
                </button>
            </a>
            <button @click="logout()">
                Logout <i class="pi pi-power-off"></i>
            </button>
        </div>
    </span>
</template>

<script>
export default {
    name: 'AccountInfoDropdown',
    data() {
        return {
            account_dropdown_active: false
        }
    },
    methods: {
        logout() {
            // remove authorisation
            this.$store.dispatch("authenticator/removeAuthorisation")
            // redirect to login page
            this.$router.push("/login")
        },
        accountDropdownToggle() {
            this.account_dropdown_active = !this.account_dropdown_active
        },
        accountDropdownOff(event) {
            // checks if click event occured outside element and closes
            //   dropdown if so.
            if (!this.$el.contains(event.target)) {
                this.account_dropdown_active = false
            }
        }
    },
    computed: {
        username: function () {
            return this.$store.state.authenticator.username
        }
    },
    mounted() {
        document.addEventListener('click', this.accountDropdownOff)
    },
    beforeUnmount() {
        document.removeEventListener('click', this.accountDropdownOff)
    }
}
</script>

<style scoped>
/* 
    Use absolute position and right/left to center the tab options and move others to edge
*/

#account-info {
    /* relative position to contain the absolute positioned dropdown */
    position: relative;
}

#account-info button i {
    pointer-events: none;
}

.account-button {
    border-style: solid;
    border-radius: 2rem 2rem 2rem 2rem;
    border-color: transparent;
    background-color: inherit;
    color: var(--colour-4);
    display: flex;
    align-items: center;
    padding: 0 1rem 0 1rem;
    border-width: 1px;
}

.account-button:hover,
.dropdown-content button:hover {
    background-color: var(--colour-9);
    cursor: pointer;
}

.account-button.active {
    border-radius: 2rem 2rem 0 0;
    border-color: var(--colour-4);
    border-bottom-color: var(--colour-1);
}

#account-info img {
    height: 3rem;
    margin: 0 1rem 0 0.5rem;
}

.dropdown-content {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--colour-1);
    border-color: var(--colour-4);
    border-radius: 0 0 1rem 1rem;
    border-style: solid;
    border-width: 1px;
}

.dropdown-content a {
    width: 100%;
}

.dropdown-content button {
    color: var(--colour-4);
    background-color: transparent;
    border: 0;
    padding: 1rem 0 1rem 0;
    width: 100%;
}

.dropdown-content button i {
    margin-left: 1rem;
}

.dropdown-content > button:last-child {
    border-radius: 0 0 1rem 1rem;
}
</style>
