<!--
    Overview tab on Individual page
-->

<template>
    <div id="overview-content">
        <div class="left-cards">
            <div class="status" :class="statusColourClass">
                <div v-if="loading.managementKpis">LOADING</div>
                <i v-else-if="managementKpisData.end_date_status === 'confirmed'" class="sprint-calendar-grey" />
                <i v-else class="sprint-alert" />
                <div v-if="loading.managementKpis">LOADING</div>
                <div v-else class="content">
                    <div v-if="managementKpisData.end_date_status === 'confirmed'" class="value">End date confirmed
                    </div>
                    <div v-else class="value">Missing removal date</div>
                    <div class="extra">
                        Planners and authorised users: view TSR information in
                        <a href="https://pps.networkrail.co.uk/welcome.htm" target="_blank" rel="noopener noreferrer">
                            PPS</a>
                        <br>
                        Controllers and authorised users: view ESR information in
                        <a href="https://nrsdb.uk/login.php" target="_blank" rel="noopener noreferrer">
                            NRSDB</a>
                    </div>
                </div>
            </div>
            <div class="owner">
                <i class="sprint-profile-inverted-dark" />
                <div v-if="loading.managementKpis">LOADING</div>
                <div v-else class="content">
                    <div class="value">
                        {{
                            managementKpisData.requestor ?
                                managementKpisData.requestor :
                                'Unknown'
                        }}
                    </div>
                    <div class="extra">Owner of actions</div>
                </div>
            </div>
            <Actions />
        </div>
        <OperatorImpact />
    </div>
</template>

<script>
import axios from 'axios';
import Actions from './Actions.vue';
import OperatorImpact from './operator_impact/OperatorImpact.vue'

export default {
    name: "Overview",
    components: {
        Actions,
        OperatorImpact
    },
    data() {
        return {
            loading: {
                managementKpis: true
            },
            managementKpisData: null
        }
    },
    computed: {
        referenceNumber() {
            // returns reference number for currently selected speed restrictions, or 
            //  for the first speed restriction in table if one hasn't been selected

            // current reference number for currently selected speed restriction
            let ref = this.$store.state.activeIndividualSpeedRestriction
            // TODO: sort out what happens if no reference is selected
            return ref
        },
        statusColourClass() {
            if (this.loading.managementKpis) {
                return "grey"
            }
            else if (this.managementKpisData.end_date_status === "confirmed") {
                return "grey"
            }
            else if (this.managementKpisData.end_date_status === "missing") {
                return "red"
            }
            else {
                return "grey"
            }
        }
    },
    watch: {
        referenceNumber: function () {
            this.getManagementKpiData()
        }
    },
    methods: {
        getManagementKpiData() {
            this.loading.managementKpis = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/overview-managements-kpis`,
                method: "GET",
                params: { reference: this.referenceNumber },
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.managementKpisData = response.data
                    this.loading.managementKpis = false
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        }
    },
    created() {
        this.getManagementKpiData()
    },
}
</script>

<style scoped>
#overview-content {
    height: 100%;
    display: flex;
}

.left-cards {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 0.75rem;
}

.status,
.owner {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0.3rem 0 0.3rem 0.5rem;
    color: var(--colour-6)
}

.status .value,
.owner .value {
    font-size: 1.5rem;
    font-weight: bold;
}

.status i.sprint-calendar-grey {
    margin-right: 1rem;
}

.status i.sprint-alert {
    margin-right: 1.35rem;
    margin-left: 0.5rem;
}

.status.red .value {
    color: var(--colour-7);
}

.status.green .value {
    color: var(--colour-8);
}

.status.grey .value {
    color: var(--colour-6);
}

.owner {
    margin: 0.75rem 0 0.75rem 0;
}

.owner i {
    margin-left: 0.5rem;
    margin-right: 1.55rem;
}

a {
    color: var(--colour-6)
}

a:hover {
    font-weight: bold;
}
</style>
