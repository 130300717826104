<template>
    <Card style="width: 400px">
        <template #header-left>
            Speed restriction map
        </template>
        <template #header-right>
            <div v-if="this.loading" class="loader">
            </div>
            <div v-else-if="this.error" class="error">
                <i class="none pi pi-ban" />
            </div>
        </template>
        <template #body>
            <div style="height: 100%; width: 100%">
                <l-map ref="map" :zoom="map.zoom" :center="map.centre" :bounds="map.bounds"
                    :options="{ attributionControl: false }">
                    <l-control-layers :collapsed="true" :hideSingleBase="true" />
                    <l-tile-layer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                        layer-type="base" name="OpenStreetMap" />
                    <l-layer-group layer-type="overlay" name="Regions">
                        <l-geo-json :geojson="map.regions" :options="regionOptions"
                            :options-style="regionStyleFunction" />
                    </l-layer-group>
                    <l-polyline v-for="item in map.polylines" :key="item.reference" :lat-lngs="item.points"
                        @mouseover="srMouseOver">
                        <l-popup :content="restrictionPopupContent(item)" :options="{ closeButton: true }" />
                    </l-polyline>
                </l-map>
            </div>
        </template>
    </Card>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { feature } from "topojson-client";
import { latLng, latLngBounds } from "leaflet";
import { LControlLayers, LGeoJson, LLayerGroup, LMap, LPolyline, LPopup, LTileLayer } from "@vue-leaflet/vue-leaflet";
import axios from "axios";
import Card from "../common/Card.vue";
import rnc from "src/functions/reference_number_conversion.js";

export default {
    name: "RouteRegionMap",
    components: {
        Card,
        LControlLayers,
        LGeoJson,
        LLayerGroup,
        LMap,
        LPolyline,
        LPopup,
        LTileLayer
    },
    data() {
        return {
            loading: true,
            error: false,
            reference_bounds: null,
            map: {
                centre: latLng(54.6096138, -5.1805185),
                zoom: 6,
                polylines: null,
                bounds: null,
                regions: null
            }
        }
    },
    computed: {
        regionColourMap() {
            return {
                "Scotland": "#0000ff",
                "North West & Central": "#00ff00",
                "Eastern": "#ff6600",
                "Southern": "#00ffe6",
                "Wales & Western": "#ff0000"
            };
        },
        regionOptions() {
            return {
                // The region GeoJson layer needs to be non-interactive so that
                // the speed restriction polyline items receive events (for
                // their associated popups).
                interactive: false,
                onEachFeature: (feature, layer) => {
                    layer.options.fillColor = this.regionColourMap[
                        feature.properties.REGION_NAM
                    ];
                }
            };
        },
        regionStyleFunction() {
            return () => {
                return {
                    color: "#ffffff",
                    weight: 2
                };
            };
        }
    },
    methods: {
        srMouseOver(e) {
            e.target.openPopup();
        },
        getData() {
            fetch("regions.topojson")
                .then((response) => response.json())
                .then((data) => {
                    for (let key in data.objects) {
                        let geojson = feature(data, data.objects[key]);
                        this.map.regions = geojson;
                    }
                });

            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/route-region-map`,
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.loading = false;
                    this.map.polylines = response.data;
                    this.map.bounds = latLngBounds(
                        this.map.polylines.map(p => p.points)
                    );

                    // Create a map of SR references -> lat/long bounds.
                    this.reference_bounds = Object.fromEntries(
                        this.map.polylines.map(
                            p => [p.reference, latLngBounds(p.points)]
                        )
                    );
                })
                .catch(error => {
                    this.loading = false;
                    this.error = true;

                    console.log(error)
                });
        },
        restrictionPopupContent(sr_item) {
            let restrictionLink = this.$router.resolve(
                `/home/individual/overview/${rnc.referenceToUriReference(sr_item.reference)}`
            );

            return `<b>Reference:</b> <a href="${restrictionLink.href}">${sr_item.reference}</a>
            <br />
            <b>ELR:</b> ${sr_item.ELR}
            <br />
            <b>TRID:</b> ${sr_item.TRID}
            <br />
            <b>Miles.chains:</b> ${sr_item.miles_from}.${sr_item.chains_from} to ${sr_item.miles_to}.${sr_item.chains_to}`;
        },
        navigateToReference(reference) {
            if (reference in this.reference_bounds) {
                this.$refs.map.leafletObject.flyToBounds(
                    this.reference_bounds[reference],
                    {
                        duration: 0.5,
                        maxZoom: 15
                    }
                );
            }
            else {
                console.log(`${reference} not on map!`);
            }
        }
    },
    mounted() {
        this.getData();
    }
}
</script>

<style scoped>
::v-deep .body {
    padding-left: 0;
    padding-right: 0;
}

/* Low z-index so the user dropdown doesn't get hidden behind the map */
.leaflet-container {
    z-index: 1;
}

.scroll-content {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
}

.loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
