<template>
    <div id="details-content">
        <div class="left-cards">
            <div class="last-updated">
                <div class="content">
                    <div class="title">Last updated</div>
                    <div v-if="loading" class="value">LOADING</div>
                    <div v-else class="value">{{ lastUpdatedDate() }}</div>
                    <div class="extra">
                        Planners and authorised users: view TSR information in
                        <a href="https://pps.networkrail.co.uk/welcome.htm" target="_blank" rel="noopener noreferrer">
                            PPS</a>
                        <br>
                        Controllers and authorised users: view ESR information in
                        <a href="https://nrsdb.uk/login.php" target="_blank" rel="noopener noreferrer">
                            NRSDB</a>
                    </div>
                </div>
            </div>
            <Card class="missing-information">
                <template #header-left>
                    Missing information
                </template>
                <template #body>
                    <div v-if="loading">LOADING</div>
                    <div v-else class="scroll-content">
                        <div class="missing-info-header">Column</div>
                        <div>
                            <div v-for="(item, index) in missingInformationList()" :key="index"
                                class="missing-info-item">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
        <Card id="information-sheet">
            <template #header-left>
                Information sheet
            </template>
            <template #body>
                <div v-if="loading">LOADING</div>
                <div v-else class="scroll-content">
                    <DataTable :value="informationList()" :autoLayout="true">
                        <Column field="column" header="Column">
                        </Column>
                        <Column field="information" header="Information">
                            <template #body="{ data }">
                                <b>{{ data.information }}</b>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import axios from "axios";
import Card from '../common/Card.vue';
import DataTable from "primevue/datatable/sfc";
import Column from "primevue/column/sfc";
import format from "src/functions/formatter.js"

export default {
    name: "Details",
    components: {
        Card,
        DataTable,
        Column
    },
    data() {
        return {
            loading: true,
            speedRestrictionData: null
        }
    },
    columnsToIgnore: ["manual_start_date"],
    computed: {
        referenceNumber() {
            // reference number for currently selected speed restriction
            let ref = this.$store.state.activeIndividualSpeedRestriction
            return ref
        }
    },
    watch: {
        referenceNumber: function () {
            this.getSpeedRestrictionData()
        }
    },
    methods: {
        formatColumnName(columnName) {
            // capitalises first character and replaces underscores with spaces
            return (columnName.charAt(0).toUpperCase() + columnName.slice(1)).replaceAll("_", " ")
        },
        lastUpdatedDate() {
            return format.formatDate(this.speedRestrictionData.updated_date)
        },
        missingInformationList() {
            let missingInformationList = []
            for (const [column, value] of Object.entries(this.speedRestrictionData)) {
                if (value == null & !this.$options.columnsToIgnore.includes(column)) {
                    missingInformationList.push(this.formatColumnName(column))
                }
            }
            return missingInformationList
        },
        informationList() {
            let informationList = Object.entries(this.speedRestrictionData)
            informationList = informationList.map(
                ([column, information]) => ({ column: this.formatColumnName(column), information: information })
            )
            return informationList
        },
        getSpeedRestrictionData() {
            // gets data for the speed restriction
            this.loading = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/speed-restriction-data`,
                method: "GET",
                params: { reference: this.referenceNumber },
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.speedRestrictionData = response.data
                    this.loading = false
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        },
    },
    created() {
        this.getSpeedRestrictionData()
    }
}
</script>

<style scoped>
::v-deep .body {
    padding-right: 0;
}

.scroll-content {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 1.5rem;
}

#details-content {
    height: 100%;
    display: flex;
}

.left-cards {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 0.75rem;
}

.last-updated {
    padding: 0.3rem 1.5rem 0.3rem 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: var(--colour-6);
    background-color: var(--colour-4);
}

.last-updated .title {
    font-size: 1.4rem;
}

.last-updated .value {
    font-size: 1.7rem;
    font-weight: bold;
}

.last-updated a {
    color: var(--colour-6)
}

.last-updated a:hover {
    font-weight: bold;
}

.missing-information {
    margin: 0.75rem 0 0 0;
    height: 0;
    flex-basis: 0;
    flex-grow: 1;
}

#information-sheet {
    flex-grow: 2.3;
    flex-basis: 0;
    margin: 0 0 0 0.75rem;
}

.missing-info-header {
    font-size: 1.2rem;
    font-weight: bold;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: var(--colour-2);
}

.missing-info-item {
    font-size: 1.2rem;
    padding: 0.5rem 0 0.5rem 0;
}

.missing-info-item:nth-child(2n) {
    background-color: var(--colour-2);
}

::v-deep #information-sheet .p-datatable-thead th {
    font-size: 1.2rem !important;
}

::v-deep #information-sheet .p-datatable-tbody td {
    font-size: 1.2rem;
}

::v-deep #information-sheet .p-datatable-tbody>tr:nth-child(2n) {
    background-color: var(--colour-2);
}
</style>
